import React from 'react'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import Seo from '../components/SEO/PageSeo'
import styled from 'styled-components'
import { IoLanguage } from 'react-icons/io5'

const SelectionLangage = () => {
  
  return (
    <Layout>
      <Seo />
      <Hero /*showIcon */ />
      <div className="page-center">
        <div className="text-center">
      <h1>Selection Langage</h1>
      Choisissez la langue dans laquelle vous souhaitez lire nos expériences de construction d'une maison en France:
      </div>
      <Wrapper>
        <div>
      <h2><a href="https://building-with-mca-france.netlify.app/"
            alt="Building a House with MCA in France - English Website"
            target="_blank"
            rel="noopener noreferrer"><IoLanguage /> English</a></h2>
      <p>Our experiences with Maison de la Côte Atlantique (MCA), our adventures finding and choosing a bank for a loan, obstacles, surprises do's and dont's in English.</p>
      <a href="https://building-with-mca-france.netlify.app/"
            alt="Building a House with MCA in France - English Website"
            target="_blank"
            rel="noopener noreferrer">https://building-with-mca-france.netlify.app/</a>
        </div>
      <div>
      <h2><a href="https://hausbau-mit-mca-frankreich.netlify.app"
            alt="Hausbau mit MCA in Frankreich - Deutsche Version"
            target="_blank"
            rel="noopener noreferrer"><IoLanguage /> Deutsch</a></h2>
            <p>Unsere Erfahrungen mit Maison de la Côte Atlantique (MCA), unsere Abenteuer eine Bank für einen Kredit zu finden, Hindernisse, Überraschungen und die do's und dont's beim Hausbau in Frankreich.</p>
            <a href="https://hausbau-mit-mca-frankreich.netlify.app"
            alt="Hausbau mit MCA in Frankreich - Deutsche Version"
            target="_blank"
            rel="noopener noreferrer">https://hausbau-mit-mca-frankreich.netlify.app</a>
          </div></Wrapper>
          </div>
    </Layout>
  )
}

export default SelectionLangage


const Wrapper = styled.section`

  @media screen and (min-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
  }
  width: 90%;
  margin: 2rem 2rem;
  h2 {
    text-align: center;
  }

  }
`